<template>
  <div class="after-sales">
    <div class="title">
      <svg-icon
        icon-class="message"
      />备注：本正品汇由太平洋保险公司承包，如假100倍赔偿
    </div>
    <div class="empty">
      <img src="@/assets/images/EmptyOrder.png" alt="空" />
      <p>
        您尚未有售后记录，去<router-link to="/Home">首页</router-link>看看吧
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "after-sales",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.after-sales {
  .title {
    height: 44px;
    background: #fff9f2;
    border: 1px solid #ffe5c2;
    line-height: 44px;
    font-size: 12px;
    color: #333333;
    padding: 0 16px;
    margin-bottom: 12px;
    .svg-icon {
      font-size: 20px;
      margin-right: 8px;
      vertical-align: -5px;
    }
  }
  .empty {
    padding: 107px 0;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    color: #333333;
    a {
      color: var(--subjectColor);
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>